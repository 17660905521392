<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              Expenses
            </h2>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        md="3"
        cols="5"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mobile_create_button"
          @click="exportExpenseDetail()"
        >
          <feather-icon
            icon="CheckSquareIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Export</span>
          <span class="align-middle d-md-none d-block">Export</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
          />
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <!-- <b-dropdown
                id="dropdown-form-all-work-flow"
                ref="filter_dropdown_all_work_flow"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <work-flow-filters
                  :status-filter.sync="statusFilter"
                  :category-filter.sync="categoryFilter"
                  :created-at.sync="createdAtFilter"
                  :status-options="statusOptions"
                  :category-options="categoryOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown> -->
            </div>
          </b-col>
        </b-row>
        <div v-if="createdAtFilter || categoryFilter.length || statusFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="entity in categoryFilter"
                  :key="entity"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromCategoryFilter(entity)"
                  >
                    <span class="text-capitalize">{{ resolveCategoryName(entity) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-if="createdAtFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="createdAtFilter = ''"
                  >
                    {{ createdAtFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in statusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        v-if="showBudgetedTable"
        ref="refBudgetExpenseTable"
        class="position-relative event_list budget-table"
        :items="budgetItems"
        :filter="searchQuery"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        fixed
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(code)="row">
          <span>{{ row.item.code ? row.item.code : '-' }}</span>
        </template>

        <template #row-details="data">
          <b-table-simple
            style="margin-bottom: -2px;"
            responsive
            fixed
            class="row-detail-table"
          >
            <b-tbody>
              <b-tr
                v-for="(expense, key) in data.item.expenses"
                :key="key"
              >
                <b-td
                  class="card-bg-color blank-b-simple-td"
                />
                <b-td
                  class="item-details-b-simple-td"
                >
                  <div>
                    <div><b>{{ expense.expense.purchaseRequestStringID }}</b></div>
                    <small class="text-muted">{{ expense.expense.itemsString }}</small>
                  </div>
                </b-td>
                <b-td
                  class="category-name-b-simple-td"
                  style="text-align: end;"
                >
                  {{ expense.processDate ? dateFormat(expense.processDate) : dateFormat(expense.expense.date) }}
                </b-td>
                <b-td
                  class="allocated-b-simple-td"
                >
                  <span
                    v-for="(option, index) in expense.expense.departments"
                    :key="index"
                  >
                    {{ option.name }} <span v-if="index + 1 != expense.expense.departments.length">, </span>
                  </span>
                </b-td>
                <b-td
                  class="spent-b-simple-td white-space-no-wrap"
                >
                  $ {{ numberFormat(expense.allocated) }}
                </b-td>
                <b-td
                  class="balance-b-simple-td white-space-no-wrap"
                >
                  $ {{ numberFormat(expense.balance) }}
                </b-td>
                <b-td
                  class="percentage-b-simple-td"
                />
              </b-tr>
            </b-tbody>

          </b-table-simple>
        </template>

        <template #cell(allocated)="data">
          <span class="text-bold-black white-space-no-wrap">$ {{ numberFormat(data.item.allocated) }}</span>
        </template>

        <template #cell(spent)="data">
          <span class="text-bold-black white-space-no-wrap">$ {{ numberFormat(data.item.spent) }}</span>
        </template>

        <template #cell(remaining)="data">
          <span class="text-bold-black white-space-no-wrap">$ {{ numberFormat(data.item.remaining) }}</span>
        </template>

        <template #cell(usagePercentage)="data">
          <div>
            <b-progress
              v-b-tooltip.hover.v-primary.top="`${numberFormat(data.item.usagePercentage)}%`"
              max="100"
            >
              <b-progress-bar
                :value="data.item.usagePercentage"
                variant="primary"
              />
            </b-progress>
          </div>
        </template>
      </b-table>

      <b-table
        v-if="showNonBudgetedTable"
        ref="refAllNonBudgetExpenseTable"
        class="position-relative event_list budget-table"
        :items="nonBudgetItems"
        :filter="searchQuery"
        responsive
        :fields="nonBudgetTableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        fixed
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(costCenter)="row">
          <span>{{ row.item.expenses.length ? row.item.expenses[0].costCenter.name : '-' }}</span>
        </template>

        <template #cell(code)="row">
          <span>{{ row.item.code ? row.item.code : '-' }}</span>
        </template>

        <template #row-details="data">
          <b-table-simple
            style="margin-bottom: -2px !important;"
            responsive
            fixed
          >
            <b-tbody>
              <b-tr
                v-for="(expense, key) in data.item.expenses"
                :key="key"
              >
                <b-td
                  class="card-bg-color percent25"
                />
                <b-td
                  class="percent25"
                >
                  <div>
                    <div><b>{{ expense.expense.purchaseRequestStringID }}</b></div>
                    <small class="text-muted">{{ expense.expense.itemsString }}</small>
                  </div>
                </b-td>
                <b-td
                  class="percent35"
                >
                  <div
                    class="d-flex justify-content-between"
                  >
                    <div style="text-align: right !important; width: 45%;">
                      <span>{{ expense.processDate ? dateFormat(expense.processDate) : dateFormat(expense.expense.date) }}</span>
                    </div>
                    <div style="margin-left: 20px; text-align: left !important; width: 55%">
                      <span
                        v-for="(option, index) in expense.expense.departments"
                        :key="index"
                      >
                        {{ option.name }} <span v-if="index + 1 != expense.expense.departments.length">, </span>
                      </span>
                    </div>
                  </div>
                </b-td>
                <b-td
                  class="percent15 white-space-no-wrap"
                >
                  $ {{ numberFormat(expense.allocated) }}
                </b-td>
              </b-tr>
            </b-tbody>

          </b-table-simple>
        </template>

        <template #cell(spent)="data">
          <span class="text-bold-black white-space-no-wrap">$ {{ numberFormat(data.item.spent) }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend, VBTooltip,
  BFormTag, BButton, BProgress, BProgressBar, BTableSimple, BTbody, BTr, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import { writeFile, utils } from 'xlsx'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useAllWorkFlowList from './useAllItemExpensesList'
import workFlowStoreModule from './itemExpenseStoreModule'
// import WorkFlowFilters from './itemExpenseFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    // BDropdown,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,
    BButton,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BProgress,
    BProgressBar,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    // WorkFlowFilters,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // props: {
  //   categoryOptions: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  data() {
    return {
      showNonBudgetedTable: false,
      showBudgetedTable: false,
      searchQuery: '',
      bulkStatus: 'active',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Archived', code: 'archived' },
      ],
      budgetItems: [],
      nonBudgetItems: [],
      expenseItems: JSON.parse(localStorage.getItem('expenseItems')) ?? [],
      nonBudgeted: localStorage.getItem('nonBudgeted') ?? 'false',
      expenseCategoryType: localStorage.getItem('expenseCategoryType') ?? '',
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },

  created() {
    if (this.nonBudgeted === 'false') {
      const formData = new FormData()
      formData.append('ids', JSON.stringify(this.expenseItems))
      this.$http.post('budget/budget/find/items-expenses', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const { budgetItems } = response.data
          budgetItems.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item._showDetails = true
            let { allocated } = item
            item.expenses.forEach(expense => {
              // if (!expense.processDate) {
              //   // eslint-disable-next-line no-param-reassign, no-multi-assign
              //   expense.balance = (allocated -= expense.allocated)
              // }
              // eslint-disable-next-line no-param-reassign, no-multi-assign
              expense.balance = (allocated -= expense.allocated)
            })
            this.budgetItems.push(item)
          })

          this.showBudgetedTable = true

          // if (budgetItems[0].budget) {
          //   this.showBudgetedTable = true
          // } else {
          //   this.showNonBudgetedTable = false
          // }
          // console.log(this.budgetItems)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      const formData = new FormData()
      formData.append('ids', JSON.stringify(this.expenseItems))
      formData.append('costCenter', localStorage.getItem('overviewNewCostCenter'))
      formData.append('budgetPeriod', localStorage.getItem('overviewNewBudgetaryPeriod'))
      this.$http.post('budget/budget/find/non-budgeted/items-expenses', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const { budgetItems, expenses } = response.data
          budgetItems.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item._showDetails = true
            const filteredData = expenses.filter(expense => expense.expenseCategory === `${item._id}`)
            // eslint-disable-next-line no-param-reassign
            item.expenses = filteredData
            // eslint-disable-next-line no-param-reassign
            item.spent = filteredData.reduce((n, { allocated }) => n + allocated, 0)
            this.nonBudgetItems.push(item)
          })
          this.showNonBudgetedTable = true
          // console.log(this.budgetItems)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_all_work_flow.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_work_flow.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },

    removeFromCategoryFilter(item) {
      const index = this.categoryFilter.indexOf(item)
      if (index > -1) {
        this.categoryFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.createdAtFilter = ''
      this.categoryFilter = []
      this.statusFilter = []
    },
    resolveCategoryName(val) {
      const category = this.categoryOptions.find(o => o._id === val)
      if (category) {
        return category.name || ''
      }
      return ''
    },
    exportExpenseDetail() {
      if (this.nonBudgeted === 'false') {
        const workbook = utils.book_new()
        this.budgetItems.forEach(item => {
          // if (item.expenses.length) {
          // }
          const worksheet = utils.aoa_to_sheet([
            ['Cost Centre:', `${item.costCenter.name}`],
            ['Budgetary Period:', `${item.budgetPeriod.name} (${this.dateFormat(item.budgetPeriod.start)} - ${this.dateFormat(item.budgetPeriod.end)})`],
            ['Expense Category Type:', `${item.expenseCategoryType}`],
            [],
            [],
            ['Budget Codes', 'Sub Budgets (Expense Category)', 'Description', 'Allocated / Opening Budget', 'Spent', 'Balance', 'PO', 'Department', 'Budget Usages Percentage'],
          ])
          const itemObject = [
            {
              code: item.code ? item.code : '-',
              expenseCategoryName: item.expenseCategoryName ? item.expenseCategoryName : '-',
              description: '-',
              allocated: `$${this.numberFormat(item.allocated)}`,
              spent: `$${this.numberFormat(item.spent)}`,
              remaining: `$${this.numberFormat(item.remaining)}`,
              po: '-',
              department: '-',
              usagePercentage: `${this.numberFormat(item.usagePercentage)}%`,
            },
          ]
          utils.sheet_add_json(worksheet, itemObject, { skipHeader: true, origin: -1 })

          const data = []
          item.expenses.forEach(obj => {
            let deptName = ''
            obj.expense.departments.forEach((dept, key) => {
              deptName += dept.name
              if ((key + 1) !== obj.expense.departments.length) {
                deptName += ', '
              }
            })
            data.push({
              code: '',
              expenseCategoryName: '',
              description: obj.expense.itemsString,
              allocated: '',
              spent: `$${this.numberFormat(obj.allocated)}`,
              remaining: `$${this.numberFormat(obj.balance)}`,
              po: obj.expense.purchaseRequestStringID,
              department: deptName,
              usagePercentage: '',
            })
          })

          utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 })

          worksheet['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 25 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }, { wch: 10 }]
          let sheetName = `${item.code ? `${item.code} - ` : ''}${item.expenseCategoryName}`
          // eslint-disable-next-line no-useless-escape
          sheetName = sheetName.substring(0, 31).replace(/[\/:?*\[\]]/g, '')
          utils.book_append_sheet(workbook, worksheet, sheetName)
        })

        writeFile(workbook, `Expense Detail - ${this.dateFormat(new Date())}.xlsx`, { compression: true })
      } else {
        const workbook = utils.book_new()
        this.nonBudgetItems.forEach(item => {
          const worksheet = utils.aoa_to_sheet([
            ['Cost Centre:', `${item.expenses[0].costCenter.name}`],
            ['Budgetary Period:', `${item.expenses[0].budgetPeriod.name} (${this.dateFormat(item.expenses[0].budgetPeriod.start)} - ${this.dateFormat(item.expenses[0].budgetPeriod.end)})`],
            ['Expense Category Type:', `${item.type}`],
            [],
            [],
            ['Budget Codes', 'Sub Budgets (Expense Category)', 'Description', 'Spent', 'PO', 'Department'],
          ])
          const itemObject = [
            {
              code: item.code ? item.code : '-',
              expenseCategoryName: item.name ? item.name : '-',
              description: '-',
              spent: `$${this.numberFormat(item.spent)}`,
              po: '-',
              department: '-',
            },
          ]
          utils.sheet_add_json(worksheet, itemObject, { skipHeader: true, origin: -1 })

          const data = []
          item.expenses.forEach(obj => {
            let deptName = ''
            obj.expense.departments.forEach((dept, key) => {
              deptName += dept.name
              if ((key + 1) !== obj.expense.departments.length) {
                deptName += ', '
              }
            })
            data.push({
              code: '',
              expenseCategoryName: '',
              description: obj.expense.itemsString,
              spent: `$${this.numberFormat(obj.allocated)}`,
              po: obj.expense.purchaseRequestStringID,
              department: deptName,
            })
          })

          utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 })
          worksheet['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 25 }, { wch: 10 }, { wch: 15 }, { wch: 15 }]
          let sheetName = `${item.code ? `${item.code} - ` : ''}${item.name}`
          // eslint-disable-next-line no-useless-escape
          sheetName = sheetName.substring(0, 31).replace(/[\/:?*\[\]]/g, '')
          utils.book_append_sheet(workbook, worksheet, sheetName)
        })
        writeFile(workbook, `Expense Detail - ${this.dateFormat(new Date())}.xlsx`, { compression: true })
      }
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const WORK_FLOW_APP_STORE_MODULE_NAME = 'app-all-work-flows'

    // Register module
    if (!store.hasModule(WORK_FLOW_APP_STORE_MODULE_NAME)) store.registerModule(WORK_FLOW_APP_STORE_MODULE_NAME, workFlowStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_FLOW_APP_STORE_MODULE_NAME)) store.unregisterModule(WORK_FLOW_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Archived', value: 'archived' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchWorkFlows,
      tableColumns,
      nonBudgetTableColumns,
      perPage,
      currentPage,
      totalWorkFlows,
      workFlowData,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refBudgetExpenseTable,
      refetchData,

      // UI
      resolveWorkFlowStatusVariant,

      // Extra Filters
      statusFilter,
      categoryFilter,
      createdAtFilter,
    } = useAllWorkFlowList()
    return {

      // Sidebar

      fetchWorkFlows,
      tableColumns,
      nonBudgetTableColumns,
      perPage,
      currentPage,
      totalWorkFlows,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refBudgetExpenseTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      workFlowData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveWorkFlowStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
      categoryFilter,
      createdAtFilter,
    }
  },
}
</script>

<style lang="scss">
.budget-table table {
  min-width: 1600px !important;
}

.b-table-details td:first-child {
  padding: 0 !important;
}
.remove-extra-padding{
   padding-bottom: 0 !important;
}
@media (max-width: 767px) {
  .table td {
      white-space: normal;
  }
  .table th {
    white-space: normal;
  }
}
.card-bg-color{
  background: #F5F8FB;
}
/* non-budget simple table css */
.blank-b-simple-td-non-budget {
   width: 18%;
}
.item-details-b-simple-td-non-budget{
  width: 21%;
}
.category-name-b-simple-td-non-budget {
  width: 10%;
}
.allocated-b-simple-td-non-budget {
  width: 18%;
}
.blank-simple-td-non-budget {
  width: 0%;
}
.spent-b-simple-td-non-budget {
  width: 10%;
}
/* budget simple table css */
.blank-b-simple-td {
  width: 15%;
  min-width: 15%;
 }
.item-details-b-simple-td{
  width: 20%;
  min-width: 20%;
}

.category-name-b-simple-td {
     width: 20%;
     min-width: 20%;
}
.allocated-b-simple-td {
     width: 15%;
     min-width: 15%;
}
.spent-b-simple-td {
      width: 10%;
      min-width: 10%;
}

.balance-b-simple-td{
     width: 10%;
     min-width: 10%;
}
.percent10 {
  width: 10%;
  min-width: 10%;
}
.percent15 {
  width: 15%;
  min-width: 15%;
}
.percent20 {
  width: 20%;
  min-width: 20%;
}
.percent25 {
  width: 25%;
  min-width: 25%;
}
.percent30 {
  width: 30%;
  min-width: 30%;
}
.percent35 {
  width: 35%;
  min-width: 35%;
}
.percentage-b-simple-td{
     width: 10%;
     min-width: 10%;
}
.no-padding-table .table td:last-child {
  padding: 12px 39px !important;
}
.white-space-no-wrap {
  white-space: nowrap;
}
/* ./ b-simple-table css */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
